<template>
  <div
    :style="[
      $vuetify.breakpoint.width <= 959
        ? { marginBottom: '471px' }
        : { marginBottom: '575px' },
    ]"
  >
    <div class="black-box-container">
      <LeftNavBar />
      <Sidebar />
      <!-- Project header - title and information -->
      <section>
        <v-layout row wrap align-center justify-center>
          <v-flex xs12>
            <v-card flat color="transparent">
              <v-card-title primary-title class="justify-center">
                <div>
                  <h3
                    :style="{
                      backgroundImage: `-webkit-linear-gradient(96deg, #f66035 4%, #ff3300)`,
                    }"
                    :class="[
                      $vuetify.breakpoint.width <= 1060
                        ? 'project-title-mobile'
                        : 'project-title',
                    ]"
                    class="mb-0"
                  >
                    Duda's new animations
                  </h3>
                  <div
                    class="pb-2"
                    :class="[
                      $vuetify.breakpoint.width <= 1060
                        ? 'project-subtitle-extended-mobile'
                        : 'project-subtitle-extended',
                    ]"
                  >
                    Next generation animations of the Duda website builder
                    platform
                  </div>
                </div>
              </v-card-title>
            </v-card>
          </v-flex>
        </v-layout>

        <v-layout
          :style="[isMobile ? { borderTop: '1px solid #262626' } : '']"
          class="row wrap"
          :class="[
            $vuetify.breakpoint.width >= 1426
              ? 'justify-center'
              : 'justify-start',
          ]"
        >
          <v-flex
            class="px-3"
            :class="[
              isMobile
                ? 'project-info-box-mobile xs6'
                : 'project-info-box shrink',
            ]"
          >
            <v-card flat color="transparent" height="100%">
              <v-card-title>
                <div :class="[isMobile ? 'mx-3' : '']">
                  <span
                    :class="[
                      $vuetify.breakpoint.width <= 1060
                        ? 'grey-title-mobile'
                        : 'grey-title',
                    ]"
                    class="d-block mb-2"
                    >Type</span
                  >
                  <span
                    :class="[
                      $vuetify.breakpoint.width <= 1060
                        ? 'headline-mobile'
                        : 'headline-desktop',
                    ]"
                    >Feature</span
                  >
                </div>
              </v-card-title>
            </v-card>
          </v-flex>

          <v-flex
            class="px-3"
            :class="[
              isMobile
                ? 'project-info-box-mobile xs6'
                : 'project-info-box shrink',
            ]"
          >
            <v-card flat color="transparent" height="100%">
              <v-card-title>
                <div :class="[isMobile ? 'mx-3' : '']">
                  <span
                    :class="[
                      $vuetify.breakpoint.width <= 1060
                        ? 'grey-title-mobile'
                        : 'grey-title',
                    ]"
                    class="d-block mb-2"
                    >Data</span
                  >
                  <span
                    :class="[
                      $vuetify.breakpoint.width <= 1060
                        ? 'headline-mobile'
                        : 'headline-desktop',
                    ]"
                    >July, 2020</span
                  >
                </div>
              </v-card-title>
            </v-card>
          </v-flex>

          <v-flex
            class="px-3"
            :class="[
              isMobile
                ? 'project-info-box-mobile xs6'
                : 'project-info-box shrink',
            ]"
          >
            <v-card flat color="transparent" height="100%">
              <v-card-title>
                <div :class="[isMobile ? 'mx-3' : '']">
                  <span
                    :class="[
                      $vuetify.breakpoint.width <= 1060
                        ? 'grey-title-mobile'
                        : 'grey-title',
                    ]"
                    class="d-block mb-2"
                    >Role</span
                  >
                  <span
                    :class="[
                      $vuetify.breakpoint.width <= 1060
                        ? 'headline-mobile'
                        : 'headline-desktop',
                    ]"
                    >Product Designer</span
                  >
                </div>
              </v-card-title>
            </v-card>
          </v-flex>

          <v-flex
            class="px-3"
            :class="[
              isMobile
                ? 'project-info-box-mobile xs6'
                : 'project-info-box shrink',
            ]"
          >
            <v-card flat color="transparent" height="100%">
              <v-card-title>
                <div :class="[isMobile ? 'mx-3' : '']">
                  <span
                    :class="[
                      $vuetify.breakpoint.width <= 1060
                        ? 'grey-title-mobile'
                        : 'grey-title',
                    ]"
                    class="d-block mb-2"
                    >Company</span
                  >
                  <span
                    :class="[
                      $vuetify.breakpoint.width <= 1060
                        ? 'headline-mobile'
                        : 'headline-desktop',
                    ]"
                    >Duda</span
                  >
                </div>
              </v-card-title>
            </v-card>
          </v-flex>
        </v-layout>
        <v-spacer class="hidden-sm-and-down pb-5"></v-spacer>
      </section>

      <!-- Project - two images section -->
      <!-- <section id="two-images">
        <v-card
          flat
          color="transparent"
          :style="{
            background: `linear-gradient(18deg, #f66035 3%, #ff3300 100%)`,
          }"
        >
          <v-layout
            row
            align-center
            justify-center
            fill-height
            class="img-container"
          >
            <v-img
              :class="[
                $vuetify.breakpoint.width <= 600
                  ? 'left-img-mobile'
                  : 'left-img',
              ]"
              contain
              :src="headerImage"
            ></v-img>
          </v-layout>
        </v-card>
      </section> -->
      <section id="two-images">
        <v-card
          flat
          color="transparent"
          :style="{
            background: `linear-gradient(18deg, #f66035 3%, #ff3300 100%)`,
          }"
        >
          <v-layout
            row
            align-center
            justify-center
            fill-height
            class="img-container"
          >
            <v-img
              :class="[
                $vuetify.breakpoint.width <= 600
                  ? 'left-img-mobile'
                  : 'left-img',
              ]"
              contain
              :src="headerImage"
            ></v-img>
          </v-layout>
        </v-card>
      </section>

      <!-- Project info section - About and challenge -->
      <section id="project-info">
        <v-card flat color="transparent">
          <v-layout row wrap align-start justify-center style="margin-top: 5em">
            <v-flex xs11 md6 lg5>
              <v-card-title class="pb-2">
                <span
                  :class="[
                    $vuetify.breakpoint.width <= 1060
                      ? 'grey-title-mobile'
                      : 'grey-title',
                  ]"
                  >About Duda</span
                >
              </v-card-title>
              <v-card-text class="pt-1">
                <span class="project-top-text"
                  >Duda is the leading web design platform for companies that
                  offer web design services to small businesses. We serve all
                  types of customers, from freelance web professionals and
                  digital agencies, all the way up to the largest hosting
                  companies and online publishers in the world.Loaded with
                  powerful team collaboration and client management tools, the
                  Duda platform enables users to build feature-rich, responsive
                  websites at scale. Every Duda website is automatically
                  optimised for lightning-fast loading and great out-of-the-box
                  SEO. Currently, over 14 million sites have been built on the
                  Duda platform.</span
                >
              </v-card-text>
            </v-flex>
            <v-flex xs11 md6 lg5>
              <v-card-title class="pb-2">
                <span
                  :class="[
                    $vuetify.breakpoint.width <= 1060
                      ? 'grey-title-mobile'
                      : 'grey-title',
                  ]"
                  >The Challenge</span
                >
              </v-card-title>
              <v-card-text class="pt-1">
                <span class="project-top-text"
                  >Duda has now only entrance animations. The old animations
                  library doesn’t fit to scale / have controllers over the
                  animations so we needed to start from scratch. The fact Duda
                  has only entrance animations also affects the UX side of it -
                  how will a more complicated animations look like? Where would
                  we put it? (it’s now located on right click -> animations
                  dropdown - obviously doesn’t fit to scale).</span
                >
              </v-card-text>
            </v-flex>
          </v-layout>
        </v-card>
      </section>

      <v-spacer></v-spacer>

      <!-- Why I did what I did section -->
      <section id="why" class="container">
        <v-card
          class="why-container py-2"
          :class="[isMobile ? 'py-2' : 'py-2 pl-4 pr-5']"
        >
          <v-card-title>
            <div class="why-title">My role in the project</div>
          </v-card-title>
          <v-card-text class="why-text">
            I was the Product Designer & Product Manager of this project. It was
            a project of my squad, called “Fast & Beautiful”.<br />
            Our job was to give Duda’s users tools to create a beautiful
            website, quickly. <br />
            <br />I’ve collaborated with 1 front end developers, 1 back end
            developer, 1 QA and a Tech leader.<br />
            <br />
            My role was to come up with the vision for the implementation of
            animations in Duda, other than the ones we already had. I’ve worked
            UX solutions for issues we had with the old animations, and
            prioritising the new features. I’ve conducted researches, MVPs,
            specs, wireframes, designs, presentations to the team and to
            stakeholders & user surveys.
          </v-card-text>
        </v-card>
      </section>

      <v-spacer></v-spacer>

      <!-- Video section -->
      <section class="container">
        <v-card class="d-flex justify-center black-background">
          <v-flex xs12 lg10>
            <v-card class="pt-2 px-2 pb-1 mt-2 black-background">
              <video
                loop
                muted
                autoplay
                playsinline
                src="@assets/images/projects/duda's-new-animations/Animations-video.mp4"
              ></video>
            </v-card>
          </v-flex>
        </v-card>
      </section>

      <!-- Current animations section -->

      <section class="container">
        <v-layout class="d-flex justify-center">
          <v-flex xs12 lg9>
            <p class="Current-animations">Current animations</p>
            <p class="why-text">
              Duda’s current animations include only entrance trigger, and a
              couple of presets that are not editable - so you can’t edit
              anything.It includes “Fade in”, “Bounce” & “Zoom In”
              animations.You can access it by right click on any widget /
              container -> Entrance animations (dropdown).
            </p>
          </v-flex>
        </v-layout>

        <v-layout>
          <v-flex>
            <v-img
              class="mx-auto"
              max-width="369px"
              contain
              :src="animationsImg"
            ></v-img>
          </v-flex>
        </v-layout>

        <v-layout class="d-flex justify-center">
          <v-flex xs12 lg9>
            <v-divider class="divider-style mt-5 mb-5"></v-divider>
          </v-flex>
        </v-layout>

        <v-layout class="d-flex justify-center">
          <v-flex xs12 lg9>
            <p class="Current-animations">
              The problems with current animations
            </p>
            <p class="why-text">
              I’m a designer, and as one I always try to make my design to stand
              out, to be bold, to be fun - animations does exactly this.
              Animations have also become mandatory in 2020 web design
              standards, so in my opinion - a website that doesn’t have ANY
              animation - is by default below the standard.
            </p>
            <p class="why-text">
              As soon as I experienced Duda for the 1st time I asked myself -
              where are the animations?On every left click on every widget /
              container we get a popup that has 2 tabs - design and content, so
              by this behaviour I’d look it under the “design” tab, and the last
              place I’d look is on the right click.
            </p>
            <p class="why-text-bold">
              So the 1st problem is that it’s not visible.
            </p>
            <p class="why-text">
              When the user finally finds the animations dropdown - he’ll ask
              “Ok, how can I customise them?” - but there are only presets, so
              you can’t control their timing (=“delay”) & duration.
            </p>
            <p class="why-text-bold">
              So the 2nd problem is that you can’t control / customise the
              animations.
            </p>
          </v-flex>
        </v-layout>

        <v-layout class="pt-5 pb-5">
          <v-flex>
            <v-img
              class="mx-auto"
              max-width="875px"
              contain
              :src="animationsImg2"
            ></v-img>
          </v-flex>
        </v-layout>
      </section>

      <!-- Changing place, changing luck-->
      <div class="section-grey-background">
        <section class="container">
          <v-layout class="d-flex justify-center">
            <v-flex xs12 lg9>
              <p class="Current-animations">Changing place, changing luck</p>
              <p class="why-text pt-3 pb-0 mb-2">
                I’ve started by changing the location of the animations feature
                - users will find it now as a new tab when they’ll left click
                any container / widget.
                <br />
                This act was meant to make it far more visible than how it was
                before.
              </p>
              <v-layout>
                <v-flex>
                  <v-img class="mx-auto" :src="animationsImg3"></v-img>
                </v-flex>
              </v-layout>
              <p class="why-text pt-3 pb-0 mb-2">
                Secondly - we want to know what do our users want to make - what
                are they most wanted animations?<br />
                So we went and made a survey for our beta group, asking them
                about their desired animations / controllers / anything else
                they might want to have in the new animations.
                <br />
                <br />

                From here I went and made couple of sketches for the MVP and
                here is the final design:
              </p>
              <v-layout class="pt-5">
                <v-flex>
                  <v-img class="mx-auto" :src="animationsImg4"></v-img>
                </v-flex>
              </v-layout>

              <p class="why-text pt-5 pb-0 mb-2">
                We can see on the left side the animations by their names and
                triggers.<br />
                You can add an animations and it’ll open the right side
                (“Settings”) on empty state, so the user need to choose the
                trigger and the animation.<br />
                <br />

                If you choose an existing animation (as can be seen on the
                attached image) you’ll get its settings on the right side and
                you can adjust its effects.<br /><br />

                The available effects are: Position, Opacity, Scale and Rotate
                For each trigger you’ll find different controllers (for example
                - on “Viewport” trigger you can adjust the behaviour, intensity
                and the viewport start & end percentages)
              </p>
            </v-flex>
          </v-layout>
        </section>
      </div>

      <!-- Next project section -->
      <section id="next-project">
        <v-container class="pt-5">
          <v-layout class="pt-5" row wrap justify-center align-center>
            <v-flex xs12 md5 lg5 xl5>
              <v-card flat class="transparent">
                <v-card-text class="py-0 my-0">
                  <div class="next-project d-block">Next project</div>
                </v-card-text>
                <v-card-title>
                  <h3
                    :style="{
                      backgroundImage: `-webkit-linear-gradient(96deg, #f66035 4%, #ff3300)`,
                    }"
                    class="next-project-title d-block mt-0"
                    :class="[
                      $vuetify.breakpoint.width <= 1060
                        ? 'project-title-mobile-next'
                        : 'project-title',
                    ]"
                  >
                    Duda’s new text editor
                  </h3>
                </v-card-title>
                <v-card-text class="py-0 my-0">
                  <span class="next-project-category">Feature</span>
                </v-card-text>
              </v-card>
            </v-flex>
            <v-flex xs8 md5 lg7 xl5 offset-xs4 offset-md0>
              <v-card flat class="transparent">
                <v-card-text
                  class="next-project-subtitle project-subtitle-extended project-subtitle text-xs-right"
                >
                  <div
                    :style="[
                      isMobile ? { fontSize: '21px' } : { fontSize: '28px' },
                    ]"
                  >
                    Next generation text editor of the Duda website builder
                    platform
                  </div>
                </v-card-text>
              </v-card>
            </v-flex>
            <v-flex
              xs10
              md10
              lg12
              xl10
              class="mt-5"
              @mouseover="mouseHandler"
              @mouseleave="mouseHandler"
            >
              <router-link to="/projects/dudas-new-text-editor">
                <div
                  :style="[
                    isMobile
                      ? {
                          position: 'relative',
                          marginBottom: '40px',
                        }
                      : { marginBottom: '40px', overflow: 'hidden' },
                  ]"
                >
                  <v-card
                    flat
                    :style="{
                      background: `linear-gradient(104deg, #f66035 4%, #ff3300)`,
                    }"
                    style="paddingtop: 40px"
                  >
                    <v-img
                      contain
                      :src="nextImg"
                      class="mx-5 my-0 next-project-img"
                      :style="[
                        isMobile
                          ? ''
                          : { paddingLeft: '150px', paddingRight: '150px' },
                      ]"
                    ></v-img>
                  </v-card>
                  <span
                    v-if="isMobile"
                    class="big-btn-arrow"
                    :style="{
                      background: `url(@assets/images/projects/arrow.png) center center, linear-gradient(104deg, #f66035 4%, #ff3300)`,
                    }"
                  >
                    <v-img
                      class="arrow"
                      :src="require(`@assets/images/projects/arrow.png`)"
                    ></v-img>
                  </span>
                </div>
              </router-link>
            </v-flex>
          </v-layout>
        </v-container>
      </section>
    </div>

    <Footer />
  </div>
</template>

<script>
import Sidebar from "@components/_sidebarNav.vue";
import LeftNavBar from "@components/_leftSideNav.vue";
import Footer from "@components/_footer.vue";

export default {
  data() {
    return {
      isMobile: false,
      headerImage: require("@assets/images/projects/duda's-new-animations/duda's_new_animations-1.png"),
      animationsImg: require("@assets/images/projects/duda's-new-animations/duda's_new_animations-2.png"),
      animationsImg1: require("@assets/images/projects/duda's-new-animations/aniamtions-2.png"),
      animationsImg2: require("@assets/images/projects/duda's-new-animations/aniamtions-2.png"),
      animationsImg3: require("@assets/images/projects/duda's-new-animations/animations-3.png"),
      animationsImg4: require("@assets/images/projects/duda's-new-animations/animations-4.png"),
      nextImg: require("@assets/images/projects/Dudas-new-text-editor/new-text-editor.png"),
    };
  },
  components: {
    Sidebar,
    LeftNavBar,
    Footer,
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.getWindowWidth);
      window.addEventListener("scroll", this.scrollHandler);

      //Init
      this.getWindowWidth();

      // hack to delay "component visibility change" on render of all components
      setTimeout(() => {
        this.hasFinishedRendering = true;
      }, 500);
    });
  },
  methods: {
    getWindowWidth() {
      this.windowWidth = document.documentElement.clientWidth;
      if (this.windowWidth <= 959) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    },
    mouseHandler(e) {
      // Next project section animation
      let nextProjectSubtitle = document.querySelector(
        ".next-project-subtitle"
      );
      let nextProjectImg = document.querySelector(".next-project-img");

      if (!this.isMobile) {
        if (e.type === "mouseover") {
          nextProjectSubtitle.style.opacity = 1;
          nextProjectImg.style.transform = "scale(1)";
        } else {
          nextProjectSubtitle.style.opacity = 0.7;
          nextProjectImg.style.transform = "scale(1.05)";
        }
      }
    },
  },
};
</script>


<style scoped lang="scss">

.section-grey-background {
  width: 100%;
  background-color: rgba(216, 216, 216, 0.13);
  padding: 50px 0px;
}
.divider-style {
  border: solid 1px #979797bb;
}
.ggrey-background {
  background-color: brown;
}
video {
  // margin: 3rem auto;
  cursor: pointer;
  width: 100%;

  // max-height: 400px;
}
#next-project {
  background-color: #0b0b0b !important;
  padding-bottom: 0px;
}
.why-text-bold {
  font-family: Futura-bold;
  font-size: 20px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: -0.5px;
  color: #ffffff;
}
.Current-animations {
  text-shadow: 0 0 20px rgba(255, 255, 255, 0.32);
  font-family: Futura;
  font-size: 36px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.86px;
  color: #ffffff;
}
.black-background {
  background-color: #000 !important;
}

.max-width-img {
  max-width: 1162px;
}
#single-project-desktop {
  position: relative;
  z-index: 2;
  margin-bottom: 400px;
}
.project-top-text {
  font-family: Futura-pt;
  font-size: 24px !important;
  font-weight: 300 !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29 !important;
  letter-spacing: -0.5px;
  color: #ffffff;
}
/* Project Header section */
.project-title {
  font-family: "futura-pt", sans-serif;
  font-size: 60px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -1.44px;
  text-align: center;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.project-title-mobile {
  font-size: 40px;
  font-weight: bold;
  text-align: center;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.96px;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.project-title-mobile-next {
  font-size: 40px;
  font-weight: bold;
  text-align: left;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.96px;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.project-subtitle-extended {
  font-size: 30px;
  font-weight: normal;
  font-style: oblique;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.72px;
  text-align: center;
}

.project-subtitle-extended-mobile {
  font-size: 22px;
  font-weight: normal;
  font-style: oblique;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.53px;
  text-align: center;
}

.project-third-extended {
  font-family: "futura-pt", sans-serif;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.38px;
  text-align: center;
  color: #727272;
}

.project-info-box {
  border-right: 1px solid #262626;
}

.project-info-box:last-child {
  border-right: 0px;
}

.project-info-box-mobile {
  border-bottom: 1px solid #262626;
  border-right: 1px solid #262626;
}

.project-info-box-mobile:nth-child(even) {
  border-right: 0px;
}

.project-info-box-mobile:nth-last-child(2) {
  border-bottom: 1px solid #262626;
}

.grey-title {
  font-family: Futura-pt;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.43px;
  color: #727272;
}

.grey-title-big {
  min-width: 260px !important;
}

.grey-title-mobile {
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.34px;
  color: #727272;
}

.grey-title-mobile-big {
  min-width: 265px;
}

@media only screen and (min-width: 1375px) and (max-width: 1425px) {
  .layout.row.wrap.justify-start {
    margin: 0 10em;
  }
}

@media only screen and (min-width: 1234px) and (max-width: 1374px) {
  .layout.row.wrap.justify-start {
    margin: 0 5em;
  }
}

@media only screen and (min-width: 1074px) and (max-width: 1233px) {
  .layout.row.wrap.justify-start {
    margin: 0 10em;
  }
}

@media only screen and (min-width: 960px) and (max-width: 1073px) {
  .layout.row.wrap.justify-start {
    margin: 0 5em;
  }
}

.headline-desktop {
  font-family: "Futura", sans-serif;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.58px;
  color: #ffffff;
}

.headline-mobile {
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.43px;
  color: #ffffff;
}

@media (max-width: 750px) {
  .project-title-mobile {
    margin-top: 1.5em;
  }
}

@media (max-width: 600px) {
  .project-title-mobile {
    margin-top: 2em;
  }
}

/* Two phones section */
.layout.img-container {
  padding: 7.5em 0 3.5em 0;
  width: 50%;
  margin: 0 auto;
  position: relative;
  min-height: 480px;
}

@media (min-width: 625px) {
  .layout.img-container {
    min-height: 550px;
  }
}

@media (min-width: 725px) {
  .layout.img-container {
    min-height: 650px;
  }
}

@media (min-width: 825px) {
  .layout.img-container {
    min-height: 750px;
  }
}

@media (min-width: 925px) {
  .layout.img-container {
    min-height: 955px;
  }
}

.left-img {
  position: absolute;
  max-width: 1000px;
  top: 10%;
  left: 0;
  right: -25%;
  bottom: 20%;
  -webkit-transform: translateX(-10%);
  transform: translateX(-10%);
}

.left-img-mobile {
  position: absolute;
  top: 15%;
  left: -225%;
  right: 0;
  bottom: 10%;
  -webkit-transform: translateX(15%);
  transform: translateX(15%);
}

.right-img {
  position: absolute;
  top: 15%;
  left: 0%;
  right: -10%;
  bottom: 30%;
  -webkit-transform: translateX(25%);
  transform: translateX(25%) translateY(30%);
}

.right-img-mobile {
  position: absolute;
  top: 15%;
  left: 0;
  right: -25%;
  bottom: 15%;
  -webkit-transform: translateX(25%);
  transform: translateX(25%) translateY(15%);
}

.additional-img {
  position: absolute;
  z-index: 1 !important;
  max-width: 1335px;
  top: 10px;
  left: 0;
  right: 0;
  bottom: -102%;
  margin: 0 -6em;
}

.v-card__text > span {
  font-size: 18px;
  font-weight: 500;
  letter-spacing: -0.43px;
}

/* Project mockup section */

.front-text {
  transform: translateY(400px);
  transition: 0.25s;
}

.spacer {
  height: 5em;
}

img.v-parallax__image {
  width: 140%;
}

/* Project showcase section - desktop images */
.showcase-img {
  transition: all 1.5s ease-in-out;
  transform: translateY(350px);
  opacity: 0;

  &.is-showing {
    opacity: 1;
    transform: translateY(0);
  }
}

.showcase-img-2 {
  transition: all 1.5s ease-in-out;
  transform: translateY(350px);
  opacity: 0;

  &.is-showing {
    opacity: 1;
    transform: translateY(0);
  }
}

@media only screen and (min-width: 1675px) {
  section#project-showcase-desktop.container {
    max-width: 1440px;
  }
}

/* Why I did what I did section */

.why-img {
  position: absolute;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  max-width: 210px;
}

.why-img-desktop {
  width: 20%;
  transform: translate(0%, -200%);
}

.why-img-mobile {
  width: 50%;
  transform: translate(50%, -150%);
}

.why-title {
  font-family: Futura-pt;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.62px;
  color: #727272;
}

.why-container {
  position: relative;
  border-radius: 10px;
  border: solid 1px #292929;
  background: #0b0b0b !important;
  margin: 0 auto;
  max-width: 1100px;
}

.why-text {
  font-family: Futura-pt;
  font-size: 24px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: -0.5px;
  color: #ffffff;
}

/* Client testimony section */

blockquote.testimony-mobile {
  margin-top: -10%;
}
// blockquote.testimony-desktop {
//   position: absolute;
//   margin-top: -100%;
//   height: 100%;
//   width: 100%;
//   padding-right: 50px;
//   bottom: 0;
//   top: 0;
//   right: 0;
//   left: 0;
//   transform: translate(-30%, 1600%);
//   z-index: 3;
//   font-family: FuturaCondDemi;
//   font-size: 45px;
//   font-weight: normal;
//   font-style: oblique;
//   font-stretch: normal;
//   line-height: 1.49;
//   letter-spacing: normal;
//   color: #ffffff;
// }
.blockquote.testimony-desktop {
  color: #fff;
  line-height: 1.49;
  z-index: 3;
  font-family: FuturaCondDemi;
  font-weight: normal;
  font-style: oblique;
  letter-spacing: normal;
  font-stretch: normal;
}
@media (min-width: 2200px) {
  .image-testimony-computer {
    width: 115% !important;
  }
}

/* Mobile showcase section  */

#project-showcase-mobile {
  background: transparent;
}

.mobile-showcase-title {
  width: 100%;
  opacity: 0.12;
  font-size: 240px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -5.76px;
  text-align: center;
}

.show-case-mobile-img {
  margin-top: -14%;
  margin-right: -2%;
  max-height: 1037px;
}

/* next project section */

.next-project {
  opacity: 0.48;
  font-family: Futura-pt;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 1.75px;
  color: #ffffff;
}

.next-project-title {
  font-size: 52px !important;
  text-align: left;
}

.next-project-category {
  border: solid 0.5px;
  padding: 8px 11px;
  border-radius: 15.5px;
  border-color: darkslategrey;
  font-size: 13px;
}

.next-project-subtitle {
  opacity: 0.7;
  transition: all 0.3s ease-in-out;
}

.next-project-img {
  transform: scale(1.05);
  transition: all 0.3s ease-in-out;
  max-height: 301px;
}

.big-btn-arrow {
  width: 64px;
  height: 64px;
  border: solid 6px #0b0b0b;
  border-radius: 50% !important;
  box-sizing: content-box;
  position: absolute;
  right: -10px;
  bottom: -30px;
}

.arrow {
  width: 12px;
  height: 21px;
  margin: 20px auto;
}
</style>